import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Documentation: https://rc-standalone-app.azureedge.net/daily-portion-tool/documentation.zip
 * Release Note: https://rc-standalone-app.azureedge.net/daily-portion-tool/release-note.html
 */
export const DailyPortionTool = () => {
  let navigate = useNavigate();

  const element = useRef<HTMLElement>(null);

  useEffect(() => {
    element.current?.addEventListener('savePet', (customEvent: any /* use your own types instead of any */) => {
      navigate('/my-account/home', { state: customEvent.detail });
    });
  });

  return (
    <daily-portion-tool
      ref={element}
      locale="en-US"
      country="US"
      with_save_pet={true}
    ></daily-portion-tool>
  );
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'daily-portion-tool': any;
    }
  }
}
